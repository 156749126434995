.card {
   border: none !important; 
}


.select-card {

    transition: all 0.25s ease;
    cursor: pointer;
    text-align: center;
    width: 169px;
    p {
        line-height: 1.3;
        margin-top: 1rem;
        color: $darkgreen;

    }
}

.select-card .card-img {
    transition: all 0.25s ease;
    display: block;
    margin: 0 auto;
    display: block;
    width: 100%;

    
}

.select-card:hover .card-img,.select-card:hover p {
    -webkit-filter: grayscale(20%) brightness(70%);
    filter: brightness(70%);
}


.select-card.not-selected .card-img,.select-card.not-selected p{
    -webkit-filter: grayscale(100%) brightness(80%) opacity(80%) contrast(120%);
    filter: grayscale(100%) brightness(80%) opacity(80%) contrast(120%);
}


